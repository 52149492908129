import {Row, Col} from "reactstrap";
import PhoneCard from "./phone";
import ContactLink from "./contact_link";
import { HashLink as Link } from 'react-router-hash-link';

const MainBlock = (index) => (
    <div className="noScroll">
    <table className="generalHeader">
        <tbody>
                <tr className="rowOne">
                <td>STRESSERTECH.CLUB</td>
                <td>STRESSERTECH.CLUB</td>
                <td>STRESSERTECH.CLUB</td>
                </tr>
                <tr className="rowTwo">
                <td>STRESSERTECH.CLUB</td>
                <td className="unpainted">STRESSERTECH.CLUB</td>
                <td>STRESSERTECH.CLUB</td>
                </tr>
        </tbody>
    </table>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        <img className="bg-img jija mob-hide" src="/img/metal_jija.png" alt=""/>

        <img src="/img/star_white_fill.svg" className="star-ru mob-hide" alt=""/>
        <img src="/img/star_red_stroke.svg" className="star-rd mob-hide" alt=""/>

        <img src="/img/star_white_fill.svg" className="star-lu mob-hide" alt=""/>
        <img src="/img/star_red_fill.svg" className="star-ld mob-hide" alt=""/>
        
        <div className="bumaga-grad mob-hide"/>
        <img className="bg-img bumaga1 bg-img mob-hide" src="/img/bumaga1.png" alt=""/>
        <img className="bg-img bumaga2 mob-hide" src="/img/bumaga2.png" alt=""/>

        <img className="bg-img lines mob-hide" src="/img/lines.svg" alt=""/>

        <img src="/img/star_white_stroke.svg" className="star-coll-l mob-hide" alt=""/>
        <img src="/img/star_white_stroke.svg" className="star-coll-c mob-hide" alt=""/>
        <img src="/img/star_white_stroke.svg" className="star-coll-r mob-hide" alt=""/>

        <p className="subHeader">Good <strong>booter</strong> - our <strong>mission</strong></p>
        <Link to="https://panel.stressertech.club/login" className="big-button">Customer<img src="/img/shop_ico.svg" alt=""/></Link><span className="spacer"/><Link to="#contacts" className="big-button-out">Contact us<img src="/img/link_ico.svg" alt=""/></Link>

        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        <h1>About</h1>
        <img src="/img/billboard_mockup.png" className="img-left img-fluid" alt="Building"/>
        <p className="img-text" id="about"><strong>Stressertech Club</strong> - Our service has been operating for more than 3 years, and we are continuously striving to <span className="red-txt">improve our service</span>. We specialize in finding solutions to bypass the protection of large companies such as <span className="red-txt">Cloudflare</span>. In our service, you can find <span className="red-txt">Layer 4 and Layer 7</span> attack methods, ensuring robust strategies for your needs.</p>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        <h1>Pricing</h1>

        <Row id="prices">
            <Col className="phoneCol"><PhoneCard planID={1} conc={2} bootTime={600} days={28} api={false} cost={30}/></Col>
            <Col className="phoneCol"><PhoneCard planID={2} conc={4} bootTime={1800} days={28} api={true} cost={110}/></Col>
            <Col className="phoneCol"><PhoneCard planID={3} conc={6} bootTime={2100} days={28} api={true} cost={180}/></Col>
        </Row>

        <br/><br/>

        <h1>Contacts</h1>
        <Row className="contactTable" id="contacts">
            <Col><ContactLink image={"/img/telegram.svg"} head={"Channel"} desc={"Stresser News"} tag={"@stressertech"} link={"https://t.me/stressertechproof"} visitTxt={"Visit"}/></Col>
            <Col><ContactLink image={"/img/telegram.svg"} head={"Admin"} desc={"Tech.Admin"} tag={"@stresstechadmin"} link={"https://t.me/stresstechadmin"} visitTxt={"Message"}/></Col>
            <Col><ContactLink image={"/img/telegram.svg"} head={"Hub"} desc={"Customer hub"} tag={"Panel"} link={"https://panel.stressertech.club/dashboard"} visitTxt={"Message"}/></Col>
        </Row>

        <br/><br/>
    </div>
);

export default MainBlock;