const ContactLink = ({ image,head,desc,tag,link,visitTxt }) => (
    <div className="contactLink">
        <img className="label-icon" src={image} alt=""/>
        <h2>{head}</h2>
        <br/>
        <p className="desc">{desc}</p>
        <p className="tag red-txt">{tag}</p>
        <div className="bigDickSpace"></div>
        <a href={link} className="contact-button">{visitTxt}<img src="/img/link_ico.svg" alt=""/></a>
        <div className="bigDickSpace"></div>
    </div>
);

export default ContactLink;