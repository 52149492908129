import { HashLink as Link } from 'react-router-hash-link';
import React, { useState } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
  } from 'reactstrap';

const NavBar = (index) => {
    const [isOpen, setIsOpen] = useState(false);

    const [navImg, setNavImg] = useState("/img/arrow_down.svg");

    const toggle = () => {
        setIsOpen(!isOpen);
        setNavImg(isOpen ? "/img/arrow_down.svg" : "/img/arrow_up.svg")
    }

    return (
    <Navbar className="navbar-expand-lg navBar">
        <NavbarBrand><img className="left" src="/img/logo.svg" alt="logo" /></NavbarBrand>
        
        <NavbarToggler className='mx-auto my-auto' onClick={toggle} style={{backgroundImage:"url("+navImg+")"}}/>

        <Collapse className="mx-auto my-auto centerNav" isOpen={isOpen} navbar>
            <Nav className="mx-auto my-auto centerNav" navbar>
                <NavItem><Link to="/">Main</Link></NavItem>
                <NavItem><Link to="#about">About</Link></NavItem>
                <NavItem><Link to="#prices">Pricing</Link></NavItem>
                <NavItem><Link to="#contacts">Contacts</Link></NavItem>
            </Nav>
        </Collapse>

        <a className="right" href="https://t.me/stresstechadmin"><img src="/img/telegram.svg" alt="telegram" /></a>
    </Navbar>
)};

export default NavBar;