const PhoneCard = ({ planID,conc,bootTime,days,api,cost }) => (
    <div className="phoneCard" style={{backgroundImage: "url(" + process.env.PUBLIC_URL + "/img/phone.png)"}}>
        <br/><br/>
        <h2>Plan #{planID}</h2>
        <p>Concurents <span className="r-txt">{conc}</span></p>
        <p>Boot time <span className="r-txt">{bootTime}</span></p>
        <p>Days <span className="r-txt">{days}</span></p>
        <p>API <span className="r-txt">{api===false ? "False" : "True"}</span></p>
        <p className="red">Cost <span className="r-txt">{cost}$</span></p>
        <button to="https://panel.stressertech.club/purchase" className="order-button">Order<img src="/img/shop_ico.svg" alt=""/></button>
    </div>
);

export default PhoneCard;