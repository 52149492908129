import './App.css';
import { Container } from 'reactstrap';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from './components/navbar'
import LandingPage from './components/landing/landing';

function App() {
  return (
    <BrowserRouter>
      <NavBar/>
      <Container className="App" fluid>
        <Routes>
          <Route path="/">
            <Route index element={<LandingPage />} />
          </Route>
        </Routes>
      </Container>
    </BrowserRouter>
  );
}

export default App;
