// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/sf-pro/SFProDisplay-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/rockstar/RS-extrabold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Rockstar ExtraBold';
  src: local('RockStar ExtraBold'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}

body {
  margin: 0;
  background-color: #0A0A0A;
  color: #FFFFFF;
  font-family: 'SF Pro Display', Arial, Helvetica, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Rockstar ExtraBold', Arial, Helvetica, sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,wFAA6F;EAC7F,0FAA0F;AAC5F;;AAEA;EACE,iCAAiC;EACjC,4FAA2F;EAC3F,0FAA0F;AAC5F;;AAEA;EACE,SAAS;EACT,yBAAyB;EACzB,cAAc;EACd,2DAA2D;AAC7D;;AAEA;EACE,+DAA+D;AACjE","sourcesContent":["@font-face {\n  font-family: 'SF Pro Display';\n  src: local('SF Pro Display'), url(./fonts/sf-pro/SFProDisplay-Regular.ttf) format('truetype');\n  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */\n}\n\n@font-face {\n  font-family: 'Rockstar ExtraBold';\n  src: local('RockStar ExtraBold'), url(./fonts/rockstar/RS-extrabold.otf) format('opentype');\n  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */\n}\n\nbody {\n  margin: 0;\n  background-color: #0A0A0A;\n  color: #FFFFFF;\n  font-family: 'SF Pro Display', Arial, Helvetica, sans-serif;\n}\n\nh1, h2, h3, h4, h5, h6 {\n  font-family: 'Rockstar ExtraBold', Arial, Helvetica, sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
